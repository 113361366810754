import request from '@/utils/request'


// 查询用户会员套餐费用列表
export function listCombo(query) {
    return request({
        url: '/vip/vip-combo/list',
        method: 'get',
        params: query
    })
}

// 查询用户会员套餐费用分页
export function pageCombo(query) {
    return request({
        url: '/vip/vip-combo/page',
        method: 'get',
        params: query
    })
}

// 查询用户会员套餐费用详细
export function getCombo(data) {
    return request({
        url: '/vip/vip-combo/detail',
        method: 'get',
        params: data
    })
}

// 新增用户会员套餐费用
export function addCombo(data) {
    return request({
        url: '/vip/vip-combo/add',
        method: 'post',
        data: data
    })
}

// 修改用户会员套餐费用
export function updateCombo(data) {
    return request({
        url: '/vip/vip-combo/edit',
        method: 'post',
        data: data
    })
}

// 删除用户会员套餐费用
export function delCombo(data) {
    return request({
        url: '/vip/vip-combo/delete',
        method: 'post',
        data: data
    })
}
